<template>
  <div class="out">
    <van-nav-bar
      :title="$t('AccountInfo.balance')"
      left-arrow
      @click-left="$router.go(-1)"
      :border="false"
      fixed
      :placeholder="true"
    />
    <div class="out-content">
      <Background></Background>
      <div class="out-content-region">
        <div class="out-content-region-box">
          <p class="out-content-region-box-title">
            <span class="out-content-region-box-title-span"
              >{{ $t("Funds.totalAmount") }}($）</span
            >
            <van-icon name="eye-o" />
          </p>
          <p class="out-content-region-box-money">{{ allbanlance + "$" }}</p>
          <van-grid :border="false">
            <van-grid-item
              :icon="svg.out"
              :text="$t('Funds.deposit')"
              to="/funds/deposit"
            />
            <van-grid-item
              :icon="svg.enter"
              :text="$t('Funds.withdrawals')"
              to="/funds/withdrawals"
            />
            <van-grid-item
              :icon="svg.ls"
              :text="$t('Funds.transfer')"
              to="/funds/transfer"
            />
            <van-grid-item
              :icon="svg.copy_trader"
              :text="$t('Menu.fundsHistory')"
              to="/funds/history"
            />
          </van-grid>
          <!-- <van-row gutter="20" class="out-content-region-box-function">
            <van-col span="12" @click="$router.push('/funds/withdrawals')">
              <span class="out-content-region-box-function-span withdrawal">{{
                $t("Funds.withdrawal")
              }}</span>
            </van-col>
            <van-col span="12" @click="$router.push('/funds/deposit')">
              <span class="out-content-region-box-function-span recharge">{{
                $t("Funds.recharge")
              }}</span>
            </van-col>
          </van-row> -->
        </div>
        <div class="out-content-region-more">
          <!-- <van-grid>
            <van-grid-item
              :icon="svg.out"
              :text="$t('Funds.deposit')"
              to="/funds/deposit"
            />
            <van-grid-item
              :icon="svg.qk"
              :text="$t('Funds.withdrawals')"
              to="/funds/withdrawals"
            />
            <van-grid-item
              :icon="svg.zz"
              :text="$t('Funds.transfer')"
              to="/funds/transfer"
            />
            <van-grid-item
              :icon="svg.copy_trader"
              :text="$t('Menu.fundsHistory')"
              to="/funds/history"
            />
          </van-grid> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Background from "@/components/crm/Background";
import { getmemberdata } from "@/api";
export default {
  name: "Out",
  components: { Background },
  data() {
    return { 
      allbanlance: 0.0,
      svg: {
        out: require("@/assets/image/crm/deposit.png"),
        ls: require("@/assets/image/crm/transfer.png"),
        enter: require("@/assets/image/crm/withdrawal.png"),
        copy_trader: require("@/assets/image/crm/statement.png"),
      },
    };
  },
  created() {
    this.getMoney();
  },
  methods: {
    async getMoney() {
      let res = await getmemberdata({});
      this.allbanlance = res.msg.allbanlance.toFixed(2);
    },
  },
};
</script>

<style scoped>
.out {
  background-color: #f7f8fa;
}
.out-content {
  position: relative;
  height: 100vh;
}
.out-content-region {
  padding: 0.25rem;
}
.out-content-region-box {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  padding: 12px;
  background-color: #fff;
}
.out-content-region-box-title {
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
}
.out-content-region-box-money {
  display: flex;
  justify-content: center;
  font-size: 30px;
  color: #000;
  padding-bottom: 80px;
}
.out-content-region-box-function {
  text-align: center;
}
.out-content-region-box-function-span {
  display: block;
  padding: 14px 0;
  border: 1px solid #f7f8fa;
  border-radius: 5px;
}
.recharge {
  background-color: #13b5b1;
  color: #fff;
}
.out-content-region-more {
  margin-top: 12px;
  border-radius: 8px;
  overflow: hidden;
}
.van-grid >>> .van-grid-item__text {
  white-space: nowrap;
}
.van-grid {
  display: flex;
  flex-wrap: nowrap;
}
.van-grid >>> .van-grid-item {
  width: 25%;
}
</style>